<template>
    <div>
        <!-- <div class="fixed inset-0 z-10 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div> -->
            <!--   add modal   -->
        <div class="z-50 inset-0 fixed flex justify-center items-center overflow-y-auto">
          <div class=" flex flex-col w-2/3 sm:w-5/6 lg:w-1/2 max-w-lg mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Ajouter une question - cas clinique</p>
              <svg @click="closeAddQuestionForClinicalCaseModal" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">
              <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3">   
                

                <!-- une nouvelle question -->
                <div class="flex flex-col justify-center items-center w-full">
                  <div class="flex sm:flex-row flex-col w-full">
                    <select v-model="questionType" type="text" name="" id="" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                      <option value="" disabled selected>
                        Type de question
                      </option>
                      <option value="qcs/qcm">Question à choix simple/multiple</option>
                      <option value="qroc">Question à réponse ouverte courte</option>
                    </select>
                  </div>

                  <div class="flex sm:flex-row flex-col">
                    <form @submit.prevent="setImage" class="sm:w-1/4 w-full m-1">
                      <div class="w-full bg-white border-gray flex flex-row ">
                        <label class="container mx-auto  p-4 bg-white max-w-sm rounded-lg overflow-hidden border hover:shadow-2xl transition duration-300">
                          <img class="rounded-lg w-full" :src="imageclinicalcase" alt="" />
                          <input v-if="imageFileclinicalcase === null" type="file" accept="image/*" name="image" @change="getImageclinicalcase" hidden />
                          <button v-else @click="cancelClinicalcase" class="absolute" ></button>
                        </label>
                      </div>
                    </form>

                    <div class="sm:flex sm:w-3/4 w-full">
                      <textarea v-model="field" placeholder="Veuillez introduire votre question ..." name="" id="" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                    </div>
                  </div>


                  <div v-show="questionType=='qroc'" class="sm:flex w-full">
                    <textarea v-model="fieldresponse" type="text" placeholder="Veuillez introduire la réponse type" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm"></textarea>
                  </div>
                  <div v-show="questionType=='qcs/qcm'" class="sm:flex sm:flex-col w-full">

                    <button @click="addSupposition=true" class="py-2 px-4 m-1 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                      Ajouter une supposition
                    </button>
                    <div v-show="addSupposition" class="flex flex-col m-1 border rounded-lg p-3">
                      <div class="flex flex-row justify-center items-center">
                        <input v-model="supposition" type="text" placeholder="Veuillez introduire une supposition" class="w-full p-2 m-1 bg-white border border-gray-200 rounded-lg shadow-sm">
                        <el-switch v-model="state" class="mx-2"> </el-switch>
                      </div>
                      <button @click="confirmSupposition" class="py-2 px-4 mt-2 mx-auto text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "> 
                        Confirmer l'ajout
                      </button>
                    </div>




                    <el-scrollbar max-height="128px" class="my-1">
                      <div
                        class="bg-gray-200 my-1 mx-3 p-1 rounded-lg flex flex-row justify-between items-center" v-for="supposition in suppositions" :key="supposition.id">
                        <div class="flex flex-row items-center">
                          <div
                            :class="
                              supposition.state == '1' ? 'bg-green-400' : 'bg-red-400'
                            "
                            class="h-2 w-2 m-2 rounded"
                          ></div>
                          {{ supposition.proposal }}
                        </div>
                        <svg
                          @click="onDeleteSupp(supposition.id)"
                          class="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </div>
                    </el-scrollbar>
                  </div>
                  

                </div>

                
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeAddQuestionForClinicalCaseModal"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="confirmeQuestion" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { useImageUpload } from "@/composables/useImageUpload.js";

export default {
  data () {
    return {
      questionType: '',
      field: '',
      fieldresponse: '',
      //suppositions
      addSupposition: false,
      supposition: null,
      state: true,
      nstate: null,
      suppositions: [],
      CountState: 0,
      position:1,

      AddedImage : null,
      formImage: null
    }
  },
  props:{
    closeAddQuestionForClinicalCaseModal:Function,
  },

  mounted () {
    this.eventBus.on("GetClinicalFile", (data) => {
      this.AddedImage = data;
    });
  },

  methods: {
    onDeleteSupp(id) {
      this.suppositions = this.suppositions.filter(
        (supposition) => supposition.id !== id
      );
      this.CountState = 0;
      this.suppositions.forEach((supposition) => {
        if (supposition.state === "1") {
          this.CountState = this.CountState + 1;
        }
      });
    },
    confirmSupposition() {
      if (!this.supposition) {
        alert("veuillez entrer une supposition");
        return;
      }

      if (this.state === true) {
        this.nstate = "1";
        this.CountState = this.CountState + 1;
      } else {
        this.nstate = "0";
      }


      this.suppositions = [...this.suppositions, {
        id : Math.floor(Math.random() * 1000),
        proposal: this.supposition,
        state: this.nstate,
      }];
     
      this.supposition = "";
      this.state = null;
    },

    confirmeQuestion(){

      if(this.questionType == 'qcs/qcm'){
        let typeM = "";
            if (this.CountState === 1) {
              typeM = "qcs";
            } else {
              typeM = "qcm";
            }

        const newQuestion = {
          question: this.field,
          type: typeM,
          proposals: this.suppositions,
          position: this.position,
          image: this.AddedImage
        };
        this.$emit("add-question-clinicalcas", newQuestion , this.AddedImage );
        this.position = this.position + 1;
        this.CountState = 0;
      }

      if(this.questionType == 'qroc'){
        const newQuestion = {
          question: this.field,
          type: this.questionType,
          response: this.fieldresponse,
          position: this.position,
          image: this.AddedImage
        };
        
        this.$emit("add-question-clinicalcas", newQuestion , this.AddedImage);
        this.position = this.position + 1;
      }
      
      this.field = '';
      this.questionType = '';
      this.fieldresponse = '';
      this.suppositions = [] ;
      this.cancelClinicalcase();
      this.closeAddQuestionForClinicalCaseModal();
    },
    
  },


  setup() {
    let {imageclinicalcase , getImageclinicalcase , imageFileclinicalcase , cancelClinicalcase} = useImageUpload();
    function setImage() {
    
    }
    
    return {setImage , imageclinicalcase , getImageclinicalcase , imageFileclinicalcase , cancelClinicalcase};
  },

  
};
</script>
<style>

</style>
