<template>
  <form
    @submit="confirm"
    class="flex flex-col mx-2 border rounded-lg border-gray-200 p-5"
  >
    <div class="w-full mb-1">
      <p class="font-semibold text-sm text-gray-800 text-left ml-2">Une nouvelle supposition</p>
    </div>

    <div class="flex flex-row items-center w-full mb-1">
      <div class="w-2/3">
        <input
          type="text"
          name="supposition"
          class="w-full p-2 bg-white border border-gray-200 rounded-lg focus:border-red-400 placeholder-gray-300"
          placeholder="Veuillez entrer une supposition"
          v-model="supposition"
        />
      </div>
      <div class="flex flex-col justify-center items-center w-1/3">
        <div v-if="state">Correcte</div>
        <div v-else>Fausse</div>
        <el-switch class="mt-2" v-model="state"> </el-switch>
      </div>
    </div>

    <div class="w-full mt-3 text-center">
      <button class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
        Confirmer l'ajout
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      supposition: null,
      state: true,
      Nstate: null,
    };
  },

  methods: {
    confirm(e) {
      e.preventDefault();
      if (!this.supposition) {
        alert("veuillez entrer une supposition");
        return;
      }

      if (this.state === true) {
        this.Nstate = "1";
      } else {
        this.Nstate = "0";
      }

      const newSupposition = {
        id: Math.floor(Math.random() * 1000),
        id_question: null,
        proposal: this.supposition,
        state: this.Nstate,
      };

      this.$emit("add-supp", newSupposition);

      this.supposition = "";
      this.state = null;
    },
  },
};
</script>

<style>
</style>