import { ref, watch } from "vue";
import store from "../store.js";
import useEmitter from "@/composables/useEmitter";

export function useImageUpload() {

    const eventBus = useEmitter();

    let imageFile = ref("");
    let imageUrl = ref("");
    let imageQuestUrl = ref("");

    let imageExplicationUrl = ref("");
    let imageExplicationFile = ref("");

    let imageFileclinicalcase = ref(null);
    let imageclinicalcase = ref("");

    if (store.getters.get_image != null) {
        imageUrl.value = store.getters.get_image;
    }
    else {
        imageUrl.value = "/avatar_profile.jpg"
    }

    imageQuestUrl.value = "/Empty.png"
    imageclinicalcase.value = "/Empty.png"
    imageExplicationUrl.value = "/Empty.png"


    function getImage(e) {
        if (e.target.files.length == 0) {
            imageFile.value = "";
            imageUrl.value = "";
            imageQuestUrl.value = "";
            return;
        }

        imageFile.value = e.target.files[0];
        eventBus.emit("GetImageFile", imageFile.value);

    }
    watch(imageFile, (imageFile) => {
        if (!(imageFile instanceof File)) {
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.addEventListener("load", () => {
            imageUrl.value = reader.result;
            imageQuestUrl.value = reader.result;
            imageclinicalcase.value = reader.result;
        });
    });




    function getImageclinicalcase(e) {
        if (e.target.files.length == 0) {
            imageFileclinicalcase.value = null;
            imageclinicalcase.value = null;
            return;
        }

        imageFileclinicalcase.value = e.target.files[0];
        eventBus.emit("GetClinicalFile", imageFileclinicalcase.value);

    }

    watch(imageFileclinicalcase, (imageFile) => {
        if (!(imageFile instanceof File)) {
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.addEventListener("load", () => {
            imageclinicalcase.value = reader.result;
        });
    });


    function cancel() {
        imageFile.value = "";
        eventBus.emit("GetImageFile", imageFile.value);
        if (store.getters.get_image != null) {
            imageUrl.value = store.getters.get_image;
        }
        else {
            imageUrl.value = "/avatar_profile.jpg"
        }
        imageQuestUrl.value = "/Empty.png"
        imageclinicalcase.value = "/Empty.png"
        imageExplicationUrl.value = "/Empty.png";
    }
    function cancelImageExplication() {
        imageExplicationFile.value = '';
        eventBus.emit("GetImageExplanation", imageExplicationFile.value);
        imageExplicationUrl.value = "/Empty.png";
    }
    function cancelClinicalcase() {
        imageFileclinicalcase.value = null;
        eventBus.emit("GetClinicalFile", imageFileclinicalcase.value);
        imageclinicalcase.value = "/Empty.png"
    }


    function getImageExplication(e) {
        if (e.target.files.length == 0) {
            imageExplicationFile.value = null;
            imageExplicationUrl.value = null;
            return;
        }

        imageExplicationFile.value = e.target.files[0];
        eventBus.emit("GetImageExplanation", imageExplicationFile.value);

    }

    watch(imageExplicationFile, (imageFile) => {
        if (!(imageFile instanceof File)) {
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.addEventListener("load", () => {
            imageExplicationUrl.value = reader.result;
        });
    });


    return { getImage, getImageclinicalcase,getImageExplication, imageUrl, imageQuestUrl, imageclinicalcase, imageFileclinicalcase,imageExplicationFile, imageExplicationUrl, imageFile, cancel, cancelClinicalcase, cancelImageExplication};

}