<template>
  <div class="p-4 h-full">

    <div v-show="AddQuestionForClinicalCaseModal">
      <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
      <AddQuestionForClinicalCaseExamModal @add-question-clinicalcas="addQuestionOnClinicalCase" :closeAddQuestionForClinicalCaseModal="closeAddQuestionForClinicalCaseModal" />
    </div>

    <div class="flex flex-col h-full w-full" :class="isLoading ? 'hidden' : 'block'">
      <div class="mb-2">
          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Ajouter une question</p>
      </div>
      <div class="border rounded-lg w-full h-full text-center flex">
        <div class=" m-6 w-full">

          <div class="flex sm:flex-row flex-col">
            <div class="flex flex-col sm:w-1/5 w-full sm:mx-2">
                <div class="mb-1">
                  <p class="font-semibold text-sm text-gray-800 text-left ml-2">Source</p>
                </div>

                <el-select v-model="source_id"  placeholder="source">
                    <el-option
                      v-for="item in sources"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    >
                    </el-option>
                </el-select>
              </div>
            <div class="flex flex-col sm:w-1/5 w-full sm:mx-2">
              <div class="mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Année</p>
              </div>

              <el-select @change="loadModules()" v-model="yearOfModule"  placeholder="Année du module">
                  <el-option
                    v-for="item in years"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
              </el-select>
            </div>
            <div class="flex flex-col sm:w-1/5 w-full sm:mx-2 sm:mt-0 mt-3">
              <div class="mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
              </div>

              <el-select @change="loadCourses()" v-model="moduleId" placeholder="Nom du module">
                  <el-option
                    v-for="item in modules"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
              </el-select>
            </div>
            <div class="flex flex-col sm:w-2/5 w-full sm:mx-2 sm:mt-0 mt-3">
              <div class="mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Cours</p>
              </div>

              <el-select v-model="CourseId" placeholder="Nom du cours">
                  <el-option
                    v-for="item in courses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
              </el-select>
            </div>
          </div>

          <div class="flex sm:flex-row flex-col justify-center items-center mt-4">
            <div class="flex flex-col sm:w-1/3 sm:mx-2 w-full">
              <div class="mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Type de question</p>
              </div>

              <el-select v-model="questionType" placeholder="Types de questions" class="w-full">
                <el-option v-for="item in typeq" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="flex flex-col sm:w-1/3 sm:mx-2 w-full">
              <div class="mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Année de la question</p>
              </div>
              <el-date-picker size="meduim" class="w-full" v-model="questionDate" type="month" value-format="x" placeholder="Sélectionnez une année"></el-date-picker>
            </div>

            <div class="w-1/3 mx-2 flex flex-row justify-around">
              <div class="flex flex-col justify-center items-center">
                <div>Activer</div>
                <el-switch class="mt-2" v-model="showQuestion"> </el-switch>
              </div>
              
              <div class="flex flex-col justify-center items-center mx-2">
                      <div>Certifier</div>
                      <el-switch class="mt-2" v-model="CertifiedQuestion"> </el-switch>
              </div>

            </div>
          </div>

           <div class="flex sm:flex-row flex-wrap mt-6">
            
              <el-tag
                class="mx-2 mb-1"
                v-for="tag in dynamicTags"
                :key="tag"
                closable
                type="danger"
                :disable-transitions="false"
                @close="handleClose(tag)"
            
              >
                  {{ tag }}
                </el-tag>
                <!-- <el-input
                  v-if="inputVisible"
                  ref="saveTagInput"
                  v-model="inputValue"
                  class="input-new-tag"
                  size="mini"
                  @keyup.enter="handleInputConfirm"
                  @blur="handleInputConfirm"
                >
                </el-input> -->
                <el-autocomplete
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    :fetch-suggestions="querySearch"
                    clearable
                    @select="handleSelect"
                    size="mini"
                    @keyup.enter="handleInputConfirm"
                    popper-class="mb-1"
                />
            <el-button v-else class="button-new-tag" size="small" @click="showInput"
              >+ Nouveau Tag</el-button>

          </div>
          <div class="flex sm:flex-row flex-col mx-2 mt-4">
            <form @submit.prevent="setImage" v-if="(questionType == 'qcs/qcm') || (questionType == 'qroc')" class="sm:w-1/5 w-full sm:mr-2">
              <div class="w-full bg-white border-gray flex flex-row ">
                <label class="container mx-auto  p-4 bg-white max-w-sm rounded-lg overflow-hidden border hover:shadow-2xl transition duration-300">
                  <img class="rounded-lg w-full" :src="imageQuestUrl" alt="" />
                  <input v-if="imageFile === ''" type="file" accept="image/*" name="image" @change="getImage" hidden />
                  <button v-else @click="cancel" class="absolute" ></button>
                </label>
              </div>
            </form>

            <div :class="(questionType == 'qcs/qcm' || questionType == 'qroc') ? 'sm:w-4/5' : 'w-full h-32'" class=" flex flex-col mt-4 sm:mt-0">
              <div class="mb-1">
                <p v-if="questionType !== 'clinicalcas'" class="font-semibold text-sm text-gray-800 text-left ml-2">Question</p>
                <p v-else class="font-semibold text-sm text-gray-800 text-left ml-2">Cas clinique</p>
              </div>
              <textarea v-model="Field" :placeholder="
                questionType !== 'clinicalcas'
                  ? 'Veuillez introduire un énoncé concernant votre question '
                  : 'Veuillez introduire un énoncé de votre cas clinique'
                " name="" id="" class="w-full h-full p-2 bg-white border border-gray-200 rounded-lg focus:border-red-400 placeholder-gray-300">
              </textarea>
            </div>
          </div>
          
           
          <div v-show="questionType == 'qcs/qcm'">
            <div class="m-5 text-center">
              <button
                @click="openSupp"
                class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "
              >
                Ajouter une supposition
              </button>
            </div>

            <div v-show="supp">
              <Supposition @add-supp="onAddSupp" />
            </div>

            <ul class=" mt-6">
              <li
                class="bg-gray-200 m-2 p-2 rounded-lg flex flex-row justify-between items-center
                "
                v-for="supposition in suppositions"
                :key="supposition.id"
              >
                <div class="flex flex-row items-center">
                  <div
                    :class="
                      supposition.state == '1' ? 'bg-green-400' : 'bg-red-400'
                    "
                    class="h-2 w-2 m-2 rounded"
                  ></div>
                  {{ supposition.proposal }}
                </div>
                <svg @click="onDeleteSupp(supposition.id)" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </li>
            </ul>
          </div>


          <div v-show="questionType == 'qroc'">
            <div class="flex flex-col items-center mx-2 mt-4 mb-2">
              <div class="w-full mb-1">
                <p class="font-semibold text-sm text-gray-800 text-left ml-2">Réponse type</p>
              </div>
              <div class="w-full">
                <textarea
                  v-model="typeresponse"
                  class="w-full p-2 bg-white border border-gray-200 rounded-lg focus:border-red-400 placeholder-gray-300"
                  placeholder="Veuillez entrer la réponse type de la question posée"
                ></textarea>
              </div>
            </div>
          </div>


          <div v-show="questionType == 'clinicalcas'">
            <div class="w-full mt-3 mb-4 text-center">
              <button
                @click="AddQuestionForClinicalCaseModal = true"
                class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 "
              >
                Ajouter une question
              </button>
            </div>

            <el-collapse v-if="clinicalCase != null"  v-model="activeNames" @change="handleChange" class="bg-white border rounded-lg my-2 shadow-sm">
              <el-collapse-item class="px-4"  v-for="(cas,index) in clinicalCase" :key="cas.position" :title="'Question ' + (index+1)"  >
                <div class="text-left">
                  <div class="border rounded-lg  mb-3">
                    <div class="border-b px-2 py-1">
                      Question 
                    </div>
                    <div class="p-2">
                      {{ cas.question }}
                    </div>
                  </div>

                  <div v-if="cas.type== 'qcm' || cas.type== 'qcs'" class="border rounded-lg">
                    <div class="px-2 py-1">
                      Suppositions 
                    </div>
                    <div>
                      <div class="flex justify-between items-center border-t py-1 px-2" v-for="proposal in cas.proposals" :key="proposal.id">
                        <div>
                          {{proposal.proposal}}
                        </div>
                        <div v-if="proposal.state == '1' " class="h-2 w-2 bg-green-400 rounded-full"></div>
                        <div v-else class="h-2 w-2 bg-red-400 rounded-full"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="cas.type== 'qroc'" class="border rounded-lg">
                    <div class="border-b px-2 py-1">
                      Réponse type 
                    </div>
                    <div class="p-2">
                      {{cas.response}}
                    </div>
                  </div>

                </div>
              </el-collapse-item>
            </el-collapse>

          </div>

          
          <div class="flex flex-col items-center mx-2 mt-4 mb-2">
            <div class="w-full mb-1">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Explication</p>
            </div>
            <div class="w-full">
              <!-- <textarea #123
                v-model="explanation"
                class="w-full p-2 bg-white border border-gray-200 rounded-lg focus:border-red-400 placeholder-gray-300"
                placeholder="Veuillez entrer une explication pour cette question si possible"
              ></textarea> -->
              <QuillEditor theme="snow" toolbar="full" contentType="html" ref="myQuillEditor" v-model:content="explanation" />
            </div>
            
             <form @submit.prevent="setImageExplication" class="sm:w-1/5 w-full sm:mr-2 mt-6">
              <div class="w-full bg-white border-gray flex flex-row ">
                <label class="container mx-auto  p-4 bg-white max-w-sm rounded-lg overflow-hidden border hover:shadow-2xl transition duration-300">
                  <img class="rounded-lg w-full" :src="imageExplicationUrl" alt="" />
                  <input v-if="imageExplicationFile === ''" type="file" name="image" @change="getImageExplication" hidden />
                  <button v-else @click="cancelImageExplication" class="absolute" ></button>
                </label>
              </div>
            </form>
          </div>




          <div v-if="Field">
            <div v-if="questionType == 'qroc'">
              <div v-if="typeresponse" class="w-full mt-4">
                <button
                  @click="confirmeQuestion"
                  class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                  Confirmer
                </button>
              </div>
            </div>
            <div v-if="questionType == 'qcs/qcm'">
              <div v-if="suppositions.length != 0" class="w-full mt-4">
                <button
                  @click="confirmeQuestion"
                  class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                  Confirmer
                </button>
              </div>
            </div>
            <div v-if="questionType == 'clinicalcas'">
              <div v-if="clinicalCase.length != 0" class="w-full mt-4">
                <button
                  @click="confirmeQuestion"
                  class="py-2 px-4 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                  Confirmer
                </button>
              </div>
            </div>
          </div>
  
        </div>
        
      </div>

    </div>


    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

  </div>
</template>

<script>
import Supposition from "./QuestionFile/Supposition.vue";
import store from "../../store.js";
import { useImageUpload } from "@/composables/useImageUpload.js";
import AddQuestionForClinicalCaseExamModal from '../dashboard/AddQuestionForClinicalCaseExamModal.vue'
import { ElMessage } from 'element-plus';
import {Delete} from '@element-plus/icons';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill'
import axiosIns from "../../plugins/axios.js";
Quill.register('@vueup/vue-quil', QuillEditor)



export default {
  
  components: {
    QuillEditor,
    Delete,
    Supposition,
    AddQuestionForClinicalCaseExamModal,
    ElMessage
  },
  data() {
    return {
      testImage: null,
      isLoading: false,
      currentRoute: window.location.pathname,
      source_id:null,
      yearOfModule:null,
      moduleId:null,
      questionType: null,
      CourseId: null,
      questionDate:null,
      explanation:null,
      Field: null,
      typeresponse: null,
      supp: false,
      CountState: 0,
      modules: [],
      courses: [],
      suppositions: [],
  
      clinicalCase: [],
      AddedImage : null,
      AddedImageExplication : null,
      position: 1,

      showQuestion : true,
      CertifiedQuestion: false,
      AddQuestionForClinicalCaseModal : false,

      dynamicTags: [],
      inputVisible: false,
      inputValue: '',

      autoCompTags : [],
      sources:[],
      years: [
        {
          value: '1',
          label: '1 Année',
        },
        {
          value: '2',
          label: '2 Année',
        },
        {
          value: '3',
          label: '3 Année',
        },
        {
          value: '4',
          label: '4 Année',
        },
        {
          value: '5',
          label: '5 Année',
        },
        {
          value: '6',
          label: '6 Année',
        },
        // {
        //   value: '7',
        //   label: '7 Année',
        // }
      ],

      typeq: [
        {
          value: 'qcs/qcm',
          label: 'Qcu/Qcm',
        },
        {
          value: 'qroc',
          label: 'Qroc',
        },
        {
          value: 'clinicalcas',
          label: 'Cas clinique',
        },
      ],
    };
  },

  props: {
    linkPath: Function,
  },


  mounted() {
    this.linkPath(this.currentRoute);

    this.eventBus.on("GetImageFile", (data) => {
      this.AddedImage = data;
    });

    this.eventBus.on("GetImageExplanation", (data) => {
      this.AddedImageExplication = data;
    });

    axiosIns.get("/gettags")
      .then(({ data }) => {
          this.autoCompTags = Object.values(data.tags).map((str, index) => ({ value: str, id: index + 1 }));
      })
      .catch((e) => {
        this.errormessage();
        this.isLoading = false;
      });
      
      axiosIns.get("/source")
      .then(({ data }) => {
        this.sources = data.sources;
      })
      .catch((e) => {
        this.errormessage();
        this.isLoading = false;
      });

  },

  methods: {
    handleSelect(item) {
      console.log(item);
      if (item.value) {
        this.dynamicTags.push(item.value)
        this.inputVisible = false
        this.inputValue = ''
      }
    },
    
    querySearch(queryString, cb) {
      var tags = this.autoCompTags;
      var results = queryString
        ? tags.filter(this.createFilter(queryString))
        : tags;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    addQuestionOnClinicalCase(question,addedImage){
      this.form.append('image'+question.position, addedImage);
      this.clinicalCase = [...this.clinicalCase, question];
      
    },
    closeAddQuestionForClinicalCaseModal(){
      this.AddQuestionForClinicalCaseModal = false
    },


    loadModules(){
      this.moduleId = null,
      this.CourseId = null;
      this.modules = [];
      this.courses = [];
      this.isLoading = true;
      axiosIns.get("/moduleofyear/" + this.yearOfModule)
        .then(({ data }) => {
          var mod = data.modules;
          
          let temps = []
          if(data.modules.length != undefined){
            data.modules.forEach(element => {

              const item = {
                label: element.title,
                value: element.id
              }
              temps.push(item);
            });
          }else{
             const item = {
                label: mod[Object.keys(mod)[0]].title,
                value: mod[Object.keys(mod)[0]].id
             }
             temps.push(item);
          }
          
          this.modules = temps;
          this.isLoading = false;
        })
        .catch(() => {
          this.errormessage();
          this.isLoading = false;
        });
    },


    loadCourses() {
      this.isLoading = true;
      axiosIns
        .get("/courseofmodule/" + this.moduleId)
        .then(({ data }) => {
          var cours = data.courses;
          
          let temps = []
          if(data.courses.length != undefined){
            data.courses.forEach(element => {

              const item = {
                label: element.title,
                value: element.id
              }
              temps.push(item);
            });
          }else{
             const item = {
                label: cours[Object.keys(cours)[0]].title,
                value: cours[Object.keys(cours)[0]].id
             }
             temps.push(item);
          }
          
          this.courses = temps;
          this.isLoading = false;
        })
        .catch(() => {
          this.errormessage();
          this.isLoading = false;
        });
    },
    onAddSupp(suppositions) {
      if (suppositions.state === "1") {
        this.CountState = this.CountState + 1;
      }
      this.suppositions = [...this.suppositions, suppositions];
      this.supp = false;
    },

    openSupp() {
      this.supp = true;
    },

    onDeleteSupp(id) {
      this.suppositions = this.suppositions.filter(
        (supposition) => supposition.id !== id
      );
      this.CountState = 0;
      this.suppositions.forEach((supposition) => {
        if (supposition.state === "1") {
          this.CountState = this.CountState + 1;
        }
      });
    },


    confirmeQuestion() {
      this.isLoading = true;
      let show,certified;
      if(this.showQuestion){show = '1'}else{show = '0'}
      if(this.CertifiedQuestion){certified = '1'}else{certified = '0'}
      if (this.questionType === "qroc") {
        axiosIns
          .post(
            "/question",
            {
              source_id:this.source_id,
              course_id: this.CourseId,
              question: this.Field,
              type: this.questionType,
              response: this.typeresponse,
              explanation:this.explanation,
              year: this.questionDate,
              show: show,
              Verified: certified,
              tags : JSON.stringify(this.dynamicTags)
            }
          )
          .then(({data}) => {
            if(this.AddedImage){
              this.setImage(data.id)
            }
            if(this.AddedImageExplication){
              this.setImageExplication(data.id, this.questionType)
            }
            this.isLoading = false;
            this.sucessm();
          })
          .catch(() => {
            this.isLoading = false;
            this.errorm();
          });
      } else if( this.questionType === "qcs/qcm") {
         
        if( this.CountState !== 0){
          if( this.CountState === 1){ this.questionType = 'qcs' ; }else{ this.questionType = 'qcm' ; }
        
        axiosIns
          .post("/question",
            {
              source_id: this.source_id,
              course_id: this.CourseId,
              question: this.Field,
              type: this.questionType,
              proposals: this.suppositions,
              explanation:this.explanation,
              year: this.questionDate,
              show: show,
              Verified: certified,
              tags : JSON.stringify(this.dynamicTags)
            }
          )
          .then(({data}) => { 
            if(this.AddedImage){
              this.setImage(data.id)
            }
            if(this.AddedImageExplication){
              this.setImageExplication(data.id, this.questionType)
            }
            this.isLoading = false;
            this.sucessm();
          })
          .catch((e) => {
            this.isLoading = false;
            this.errorm();
          });
      }
     }else if (this.questionType === "clinicalcas"){
       this.form.append('course_id', this.CourseId);
       this.form.append('question', this.Field);
       this.form.append('source_id', this.source_id);
       this.form.append('clinicalCase', JSON.stringify(this.clinicalCase));
       this.form.append('explanation',this.explanation)
       this.form.append('year', this.questionDate)
       this.form.append('show', show);
       this.form.append('Verified',certified);
       this.form.append('tags',JSON.stringify(this.dynamicTags));
       axiosIns
          .post("/clinicalcase",
              this.form
          )
          .then(({data}) => {
            this.isLoading = false;
            if(this.AddedImageExplication){
              this.setImageExplication(data.cliniqualCaseID, 'clinicalcas')
            }
            this.sucessm();
          })
          .catch(() => {
            this.isLoading = false;
            this.errorm();
          });

     }
      this.explanation = null
      this.questionType = null;
      this.Field = null;
      this.typeresponse = null;
      this.supp = false;
      this.suppositions = [];
      this.CountState = 0;
      this.clinicalCase= [];
      this.position = 1;
      this.dynamicTags = [];
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
    },

    showInput() {
      this.inputVisible = true
    },

    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.dynamicTags.push(inputValue)
        this.inputVisible = false
        this.inputValue = ''
      }
    },
  },
  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },
   setup() {
    let { getImage, imageQuestUrl, imageFile, cancel } = useImageUpload();
    let form = new FormData();


    let { getImageExplication, imageExplicationUrl, imageExplicationFile, cancelImageExplication } = useImageUpload();
    function setImageExplication(id, type) {
      let form = new FormData();
        form.append("image", imageExplicationFile.value);
      if(type !== 'clinicalcas') {
        
        axiosIns
          .post("/setExplanationImage/" +
              id,
            form
          )
          .then(() => {
            cancelImageExplication();
          })
          .catch(() => {
            this.errormessage();
            this.error = "Erreur d'ajout d'image";
          });
          this.imageExplicationFile = ""
          this.imageExplicationUrl = ""
      }else {
        axiosIns
          .post("/setExplanationImageCC/" +
              id,
            form
          )
          .then(() => {
            cancelImageExplication();
          })
          .catch(() => {
            this.errormessage();
            this.error = "Erreur d'ajout d'image";
          });
          this.imageExplicationFile = ""
          this.imageExplicationUrl = ""
      }
      
    }

    

    function setImage(id) {
      let form = new FormData();
      form.append("image", imageFile.value);
      axiosIns.post("/setQuestionImage/" +
            id,
          form
        )
        .then(() => {
          cancel();
        })
        .catch(() => {
          this.errormessage();
          this.error = "Erreur d'ajout d'image";
        });
        this.imageFile = ""
        this.imageQuestUrl = ""
    }
    const sucessm = () => {
      ElMessage({
        message: 'Question ajoutée avec succès.',
        type: 'success',
      })
    }
    const errorm = () => {
      ElMessage.error('Erreur lors de l\'ajout de la question.')
    }

    const errormessage = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return { getImage,getImageExplication, imageQuestUrl,imageExplicationUrl, imageFile,imageExplicationFile, cancel,cancelImageExplication, setImage,setImageExplication,sucessm,errorm,errormessage,form};
  },
};
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>